import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import MainFeature1 from "components/features/TwoColWithButton.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
// import Features from "components/features/ThreeColWithSideImage.js";


const Subheading = tw.span`uppercase tracking-wider text-sm text-orange-500`;
const Subsubheading = tw.span`uppercase tracking-wider text-sm text-blue-500`;
export default () => {
  return (
    <AnimationRevealPage>
      {/* <Header /> */}
      <MainFeature1
        subheading={<Subheading>About Us</Subheading>}
        // heading="We are a modern design agency."
        // buttonRounded={false}
        // primaryButtonText="See Portfolio"
        description="Abhinaya Harsa Kreasi is a market research operational service company located at Jalan Panglima Polim V, Number 52, Melawai, Kebayoran Baru, South Jakarta, DKI Jakarta 12160."
        description2="Abhinaya Harsa Kreasi was established in 2022, supported by a high-quality team with extensive experience in the best market research companies in Indonesia"
        imageSrc="https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=768&q=80"
      />
      <MainFeature1
        subheading={<Subheading>Visions & Missions</Subheading>}
        // heading="We aim to disrupt the design space."
        buttonRounded={false}
        // primaryButtonText="Contact Us"
        description={
          <div>
            <Subsubheading>Visions</Subsubheading>
            <ul style={{ marginBottom: "16px" }}>
              <li>To become a leading company in its field.</li>
              <li>On time in completing tasks.</li>
            </ul>

            <Subsubheading>Missions</Subsubheading>
            <ul style={{ marginBottom: "16px" }}>
              <li>
                To maintain and increase customer value and satisfaction.
                Providing employment opportunities to various levels of society.
                Building a company that is able to provide optimal welfare to
                all stakeholders.
              </li>
            </ul>
          </div>
        }
        imageSrc="https://images.unsplash.com/3/doctype-hi-res.jpg?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80"
        textOnLeft={false}
      />
      {/* <Features
        subheading={<Subheading>Our Values</Subheading>}
        heading="We follow these."
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
        cards={[
          {
            imageSrc: SupportIconImage,
            title: "24/7 Support",
            description:
              "Lorem ipsum donor amet siti ceali placeholder text alipiscing elit sed do eiusmod temport",
          },
          {
            imageSrc: ShieldIconImage,
            title: "Strong Teams",
            description:
              "Lorem ipsum donor amet siti ceali placeholder text alipiscing elit sed do eiusmod temport",
          },
          {
            imageSrc: CustomerLoveIconImage,
            title: "Customer Satisfaction",
            description:
              "Lorem ipsum donor amet siti ceali placeholder text alipiscing elit sed do eiusmod temport",
          },
        ]}
        linkText=""
      /> */}
      {/* <TeamCardGrid subheading={<Subheading>Our Team</Subheading>} /> */}
      {/* <Footer /> */}
    </AnimationRevealPage>
  );
};
