import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import MainFeature1 from "components/features/ThreeColSimple2.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";

const Subheading = tw.span`uppercase tracking-wider text-sm text-orange-500`;
export default () => {
  return (
    <AnimationRevealPage>
      {/* <Header /> */}
      <MainFeature1
        subheading={<Subheading>Data Collection Method</Subheading>}
        subheading2={<Subheading>Type Of Studies</Subheading>}
        heading="Quantitative"
        description={
          <div>
            <ul>
              <li>Pen and Paper Interview (PAPI)</li>
              <li>Computer Assisted Personal Interviewing (CAPI)</li>
              <li>Face to Face (F2F)/Door to Door</li>
              <li>Central Location (CLT)</li>
              <li>Computer Aided Telephone Interview (CATI)</li>
              <li>Street Intercept</li>
              <li>Online Survey</li>
              <li>Mobile Survey</li>
            </ul>
          </div>
        }
        description2={
          <div>
            <ul>
              <li>Usage and Atitude (U&A)</li>
              <li>Concept Test</li>
              <li>Product Placement</li>
              <li>Mystery Shopping</li>
              <li>Tracking Study</li>
              <li>Distribution Check</li>
              <li>Public Opinion</li>
              <li>Census</li>
              <li>Retail Audit</li>
            </ul>
          </div>
        }
        // buttonRounded={false}
        // primaryButtonText="See Portfolio"
        // imageSrc={quantitative}
        // imageSrc="https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=768&q=80"
      />
      <MainFeature1
        subheading={<Subheading>Data Collection Method</Subheading>}
        subheading2={<Subheading>Type Of Studies</Subheading>}
        heading="Qualitative"
        // buttonRounded={false}
        // primaryButtonText="Contact Us"
        description={
          <div>
            <ul>
              <li>Face to Face recruitment</li>
              <li>Observation</li>
              <li>Intercept</li>
            </ul>
          </div>
        }
        description2={
          <div>
            <ul>
              <li>In Depth and FGD</li>
              <li>Consumer Connection</li>
              <li>Home Visit</li>
              <li>Shadowing</li>
              <li>Accompanied Shopping</li>
            </ul>
          </div>
        }
        // imageSrc={qualitative}
        // textOnLeft={false}
      />
      <MainFeature1
        subheading={<Subheading>DP Services</Subheading>}
        subheading2={<Subheading>DP Tools</Subheading>}
        heading="Data Processing Service"
        // buttonRounded={false}
        // primaryButtonText="See Portfolio"
        description={
          <div>
            <ul>
              <li>Questionnaire Checking</li>
              <li>Coding</li>
              <li>Data Entry</li>
              <li>Processing</li>
              <li>Advanced Statistical Data</li>
            </ul>
          </div>
        }
        description2={
          <div>
            <ul>
              <li>Fully Licensed QPS and SPSS Software</li>
            </ul>
          </div>
        }
        // imageSrc={dp}
        // imageSrc="https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=768&q=80"
      />
      {/* <Features
        subheading={<Subheading>Our Values</Subheading>}
        heading="We follow these."
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
        cards={[
          {
            imageSrc: SupportIconImage,
            title: "24/7 Support",
            description:
              "Lorem ipsum donor amet siti ceali placeholder text alipiscing elit sed do eiusmod temport",
          },
          {
            imageSrc: ShieldIconImage,
            title: "Strong Teams",
            description:
              "Lorem ipsum donor amet siti ceali placeholder text alipiscing elit sed do eiusmod temport",
          },
          {
            imageSrc: CustomerLoveIconImage,
            title: "Customer Satisfaction",
            description:
              "Lorem ipsum donor amet siti ceali placeholder text alipiscing elit sed do eiusmod temport",
          },
        ]}
        linkText=""
      /> */}
      {/* <TeamCardGrid subheading={<Subheading>Our Team</Subheading>} /> */}
      {/* <Footer /> */}
    </AnimationRevealPage>
  );
};
