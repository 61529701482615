import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

// import ResponsiveVideoEmbed from "../../helpers/ResponsiveVideoEmbed.js";
import Hero from "../../images/hero.jpeg";

const Container = styled.div`
  ${tw`relative -mx-8 mt-8 bg-center bg-cover`}
`;


// const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-primary-500 opacity-25`;

const HeroContainer = tw.div`z-20 relative px-4 sm:px-8 max-w-screen-xl mx-auto`;
const TwoColumn = tw.div`pt-24 pb-32 px-4 flex justify-between items-center flex-col lg:flex-row`;
const LeftColumn = tw.div`flex flex-col items-center lg:block`;
// const RightColumn = tw.div`w-full sm:w-5/6 lg:w-1/2 mt-16 lg:mt-0 lg:pl-8`;

const Heading = styled.h1`
  ${tw`text-3xl text-center lg:text-left sm:text-4xl lg:text-5xl xl:text-6xl font-black text-gray-100 leading-none`}
  span {
    ${tw`inline-block mt-2`}
  }
`;

const SlantedBackground = styled.span`
  ${tw`relative text-orange-500 px-4 -mx-4 py-2`}
  &::before {
    content: "";
    ${tw`absolute inset-0 bg-gray-100 transform -skew-x-12 -z-10`}
  }
`;


// const StyledResponsiveVideoEmbed = styled(ResponsiveVideoEmbed)`
//   padding-bottom: 56.25% !important;
//   padding-top: 0px !important;
//   ${tw`rounded`}
//   iframe {
//     ${tw`rounded bg-black shadow-xl`}
//   }
// `;

export default ({ roundedHeaderButton }) => {
  // const [activeMenu, setActiveMenu] = React.useState(0);
  // const handleClick = (index) => {
  //   setActiveMenu(index);
  // };
  // const navLinks = [
  //   <NavLinks key={1}>
  //     <NavLink
  //       href="/"
  //       isActive={activeMenu === 0}
  //       onClick={() => handleClick(0)}
  //     >
  //       Home
  //     </NavLink>
  //     <NavLink
  //       href="#"
  //       isActive={activeMenu === 1}
  //       onClick={() => handleClick(1)}
  //     >
  //       Service
  //     </NavLink>
  //     <NavLink
  //       href="/privacy"
  //       isActive={activeMenu === 2}
  //       onClick={() => handleClick(2)}
  //     >
  //       Privacy
  //     </NavLink>
  //     <NavLink
  //       href="#"
  //       isActive={activeMenu === 3}
  //       onClick={() => handleClick(3)}
  //     >
  //       About Us
  //     </NavLink>
  //     <NavLink
  //       href="#"
  //       isActive={activeMenu === 4}
  //       onClick={() => handleClick(4)}
  //     >
  //       Contact Us
  //     </NavLink>
  //   </NavLinks>,
  //   <NavLinks key={2}>
  //     <PrimaryLink href="/#">Hire Us</PrimaryLink>
  //   </NavLinks>,
  // ];

  return (
    <>
      {/* <Header roundedHeaderButton={roundedHeaderButton} links={navLinks} /> */}
      <Container
        style={{
          backgroundImage: `url(${Hero})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        {/* <OpacityOverlay /> */}
        {/* <HeaderContainer>
        <Header roundedHeaderButton={roundedHeaderButton} />
        <StyledHeader links={navLinks} />
      </HeaderContainer> */}

        <HeroContainer>
          <TwoColumn>
            <LeftColumn>
              {/* <Notification>
                We have now launched operations in Europe.
              </Notification> */}
              <Heading>
                <span>The best</span>
                <br />
                <SlantedBackground>Research Team.</SlantedBackground>
              </Heading>
              {/* <PrimaryAction>Read Customer Stories</PrimaryAction> */}
            </LeftColumn>
            {/* <RightColumn>
            <StyledResponsiveVideoEmbed
              url="//player.vimeo.com/video/374265101?title=0&portrait=0&byline=0&autoplay=0&responsive=1"
              background="transparent"
            />
          </RightColumn> */}
          </TwoColumn>
        </HeroContainer>
      </Container>
    </>
  );
};
